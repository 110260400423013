import DashboardLayout from "src/pages/DashboardLayout.vue";
import NotFound from "src/pages/NotFoundPage.vue";
import Overview from "src/pages/Overview.vue";
import showdata from "src/pages/show_result.vue";
import detail_result from "src/pages/detail_result.vue";
import edit_result from "src/pages/edit_result.vue";
import create_result from "src/pages/create_result.vue";
import show_plateform from "src/pages/show_plateform.vue";
import show_site from "src/pages/show_site.vue";
import detail_plateform from "src/pages/detail_plateform.vue";
import edit_platform from "src/pages/edit_platform.vue";
import create_plateform from "src/pages/create_plateform.vue";
import detail_site from "src/pages/detail_site.vue";
import edit_site from "src/pages/edit_site.vue";
import create_site from "src/pages/create_site.vue";
import member from "src/pages/member.vue";
import login from "src/pages/login.vue";
import singout from "src/pages/singout.vue";
import create_member from "src/pages/create_member.vue";
import detail_member from "src/pages/detail_member.vue";
import report from "src/pages/report.vue";
import qr_code from "src/pages/qr_code.vue";
import create_qrcode from "src/pages/create_qrcode.vue";

let Webservice = {
  // mode: "history",
  path: "/web",
  component: DashboardLayout,
  redirect: "/web/service",
  children: [
    {
      path: "service/",
      name: "service",
      component: showdata
    },
    {
      path: "/detail_result",
      name: "detail_result",
      component: detail_result
    },
    {
      path: "/edit_result",
      name: "edit_result",
      component: edit_result
    },
    {
      path: "/create_result",
      name: "create_result",
      component: create_result
    },
    {
      path: "/show_plateform",
      name: "show_plateform",
      component: show_plateform
    },
    {
      path: "/show_site",
      name: "show_site",
      component: show_site
    },
    {
      path: "/detail_plateform",
      name: "detail_plateform",
      component: detail_plateform
    },
    {
      path: "/edit_platform",
      name: "edit_platform",
      component: edit_platform
    },
    {
      path: "/create_plateform",
      name: "create_plateform",
      component: create_plateform
    },
    {
      path: "/detail_site",
      name: "detail_site",
      component: detail_site
    },
    {
      path: "/edit_site",
      name: "edit_site",
      component: edit_site
    },
    {
      path: "/create_site",
      name: "create_site",
      component: create_site
    },
    {
      path: "/member",
      name: "member",
      component: member
    },
    {
      path: "/create_member",
      name: "create_member",
      component: create_member
    },
    {
      path: "/detail_member",
      name: "detail_member",
      component: detail_member
    },
    {
      path: "/report",
      name: "report",
      component: report
    },
    {
      path: "/qr_code",
      name: "qr_code",
      component: qr_code
    },
    {
      path: "/create_qrcode",
      name: "create_qrcode",
      component: create_qrcode
    }
  ]
};
let redirect = {
  path: "/redirect",
  redirect: "/web"
};
const routes = [
  {
    path: "/login",
    component: login
  },
  {
    path: "/",
    component: login
  },
  {
    path: "/singout",
    name: "singout",
    component: singout
  },
  Webservice,
  redirect,
  {
    path: "/main",
    component: DashboardLayout,
    redirect: "/main/Dashboard",
    children: [
      {
        path: "Dashboard",
        name: "Dashboard",
        component: Overview
      }
    ]
  },
  { path: "*", component: NotFound }
];

export default routes;
