<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">อัพเดตรายละเอียดเว็บไซต์</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-3">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="result_data.createdAt"
                  disabled
                />
              </div>
              <div class="col-md-7">
                <fg-input
                  label="เว็บไซต์"
                  type="text"
                  v-model="result_data.service_name"
                />
              </div>
              <div class="col-md-2">
                <label> เครือข่าย</label><br />
                <el-select
                  label="สถานะการใช้งาน"
                  class="select-primary"
                  size="large"
                  :placeholder="result_data.group"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10">
                <fg-input
                  label="ที่อยู่เว็บไซต์"
                  type="text"
                  v-model="result_data.service_url"
                />
              </div>
              <div class="col-md-2">
                <label>สถานะ</label><br />
                <el-select
                  label="สถานะการใช้งาน"
                  class="select-primary"
                  size="large"
                  :placeholder="status"
                  v-model="selects_status.simple"
                >
                  <el-option
                    v-for="option in selects_status.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ไลน์ติดต่อ (LINE-CONNECT)"
                  type="text"
                  v-model="line_connect"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="คำแนะนำ"
                  type="text"
                  v-model="result_data.comment"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <l-button type="success" wide @click="handleSave()"
                  >แก้ไขข้อมูล</l-button
                >
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import { Select, Option } from "element-ui";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    Checkbox,
    Radio,
    ChartCard,
    StatsCard
  },
  data() {
    return {
      selects: {
        simple: "",
        countries: [
          {
            label: "ABA-GROUP",
            value: "ABA-GROUP"
          },
          {
            label: "AUTO-GROUP",
            value: "AUTO-GROUP"
          },
          {
            label: "ABA-BACARAT",
            value: "ABA-BACARAT"
          },
          {
            label: "BCR-GROUP",
            value: "BCR-GROUP"
          },
          {
            label: "OTHER",
            value: "OTHER"
          }
        ]
      },
      selects_status: {
        simple: "",
        countries: [
          {
            label: "เปิดใช้งาน",
            value: "1"
          },
          {
            label: "ยกเลิกใช้งาน",
            value: "0"
          }
        ]
      },
      isChart: false,
      result_data: [],
      accept_url: "",
      click_url: "",
      platform_name: "",
      status: "",
      line_connect: ""
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/show_site");
    },
    async handleSave() {
      if (this.result_data.service_name && this.result_data.service_url) {
        let selects = this.selects.simple
          ? this.selects.simple
          : this.result_data.group;

        let selects_status = this.selects_status.simple
          ? this.selects_status.simple
          : this.result_data.status;
        let line_connect = this.line_connect;

        let payload = {
          id: this.$route.query.id,
          service_name: this.result_data.service_name,
          service_url: this.result_data.service_url,
          group: selects,
          status: parseInt(selects_status),
          comment: this.result_data.comment,
          line_connect: line_connect
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/update_site", { token: token }, { headers: headers })
          .then(res => {
            if (res.data.code === 0) {
              Swal.fire({
                title: res.data.msg,
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            } else {
              Swal.fire({
                title: res.data.msg,
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            }
          })
          .catch(() => {});
      } else {
        Swal.fire({
          title: "กรอกข้อมูลให้ครบ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true
        });
      }
    }
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: this.$route.query.id
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };
    await this.$axios
      .post("/detail_service", { token: token }, { headers: headers })
      .then(async res => {
        if (res.data.payload && this.$route.query.id && this.$route.query.web) {
          this.status =
            res.data.payload.status === 1 ? "เปิดใช้งาน" : "ยกเลิกใช้งาน";
          this.line_connect = res.data.payload.line_connect;
          this.result_data = res.data.payload;
        } else {
          this.$router.push("/");
        }
      });
  }
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
</style>
