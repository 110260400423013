<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">เพิ่มข้อมูลการโฆษณา</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-2">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="datetime"
                  disabled
                />
              </div>
              <div class="col-md-2">
                <fg-input label="พนักงาน" type="text" v-model="name" disabled />
              </div>
              <div class="col-md-4">
                <fg-input
                  label="เว็บไซต์ลงโฆษณา"
                  type="text"
                  v-model="website"
                  disabled
                />
              </div>
              <div class="col-md-2">
                <label> แพลตฟอร์ม</label><b style="color: red"> *</b><br />
                <el-select
                  label="แพลตฟอร์ม"
                  class="select-primary"
                  size="large"
                  placeholder="เลือกแพลตฟอร์ม"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <label> ช่องทางโฆษณา</label><b style="color: red"> *</b><br />
                <el-select
                  label="ช่องทางโฆษณา"
                  class="select-primary"
                  size="large"
                  placeholder="ช่องทางโฆษณา"
                  v-model="selects_add.simple"
                >
                  <el-option
                    v-for="option in selects_add.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <label> แคมเปญหลัก</label><b style="color: red"> *</b><br />
                <fg-input type="text" v-model="main_campaign" />
              </div>
              <div class="col-md-5">
                <fg-input
                  label="แคมเปญย่อย"
                  type="text"
                  v-model="second_campaign"
                ></fg-input>
              </div>
              <div class="col-md-2">
                <label> รูปแบบฟอร์มสมัคร</label><br />
                <el-select
                  label="รูปแบบฟอร์มสมัคร"
                  class="select-primary"
                  size="large"
                  placeholder="รูปแบบฟอร์มสมัคร"
                  v-model="selects_form.simple"
                >
                  <el-option
                    v-for="option in selects_form.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ลิ้งค์การตลาด"
                  type="text"
                  v-model="full_url"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input label="หมายเหตุ" type="text" v-model="comment" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <l-button type="success" wide @click="handleSave()"
                  >บันทึกข้อมูล</l-button
                >&nbsp;
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
  CheckboxGroup,
} from "element-ui";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2",
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true,
      },
      pieChart: {
        data: {
          labels: [],
          series: [],
        },
      },
      isChart: false,
      platform_name: "",
      result_data: [],
      accept_url: "",
      click_url: "",
      selects: { simple: "", countries: [] },
      selects_add: {
        simple: "",
        countries: [
          {
            label: "Website",
            value: 1,
          },
          {
            label: "Line",
            value: 0,
          },
        ],
      },
      selects_form: {
        simple: "",
        countries: [
          {
            label: "รูปแบบที่ 1",
            value: 1,
          },
          {
            label: "รูปแบบที่ 2",
            value: 2,
          },
          {
            label: "รูปแบบที่ 3",
            value: 3,
          },
        ],
      },
      datetime: "",
      website: "",
      main_campaign: "",
      second_campaign: "",
      full_url: "",
      comment: "",
      service_id: "",
      name: "",
    };
  },
  computed: {
    ...mapGetters({
      isLogin: "isLogin",
      user: "user",
    }),
  },
  methods: {
    handleEdit() {
      this.$router.push("/redirect?web=" + this.$route.query.web);
    },
    async handleSave() {
      if (
        this.main_campaign &&
        this.selects.simple &&
        this.selects_add.simple
      ) {
        let result = JSON.parse(localStorage.getItem("user"));
        let username = jwt.verify(result, "ABAsecretKeywweqwlinkmanager");
        this.name = username.result[0].Name;
        let selects = this.selects.simple
          ? this.selects.simple
          : this.result_data.platform;

        let payload = {
          employee: this.name,
          service_id: this.service_id,
          main_campaign: this.main_campaign,
          second_campaign: this.second_campaign,
          platform: this.selects.simple,
          full_url: this.full_url,
          comment: this.comment,
          channel: this.selects_add.simple,
          formRegis: this.selects_form.simple,
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user,
        };

        await this.$axios
          .post("/gen_link", { token: token }, { headers: headers })
          .then((res) => {
            if (res.data.code === 0) {
              Swal.fire({
                title: "บันทึกข้อมูล สำเร็จ!",
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                title: res.data.msg,
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true,
              });
            }
          })
          .catch(() => {});
      } else {
        Swal.fire({
          title: "กรอกข้อมูลให้ครบ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
        });
      }
    },
  },
  async mounted() {
    let result = JSON.parse(localStorage.getItem("user"));
    let username = jwt.verify(result, "ABAsecretKeywweqwlinkmanager");
    this.name = username.result[0].Name;
    this.website = this.$route.query.web;
    this.datetime = moment().format("YYYY-MM-DD HH:mm:ss");
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: this.website,
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user,
    };

    await this.$axios
      .post(
        "/list_service",
        { token: token },
        {
          headers: headers,
        }
      )
      .then(async (res) => {
        if (res.data.code === 0) {
          this.service_id = res.data.payload[0].id;
          this.website = res.data.payload[0].service_name;
        } else {
          this.$router.push("/");
        }
      })
      .catch((err) => {});

    user = JSON.parse(localStorage.getItem("user"));

    headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user,
    };

    await this.$axios
      .get("/list_platform", {
        headers: headers,
      })
      .then(async (res) => {
        let selected = [];
        let select = [];
        if (res.data.code === 0) {
          await res.data.payload.filter((word) => {
            if (word.status === 1) {
              if (word.id === parseInt(this.result_data.platform)) {
                selected.push({
                  label: word.platform_name,
                  value: word.id,
                  selected: true,
                });
                return word;
              } else {
                select.push({
                  label: word.platform_name,
                  value: word.id,
                });
              }
            }
          });
          this.selects.countries = selected.concat(select);
          this.showModal = true;
        } else {
          this.showModal = false;
          Swal.fire({
            text: "เกิดข้อผิดพลาด กรุณาลองใหม่",
            icon: "error",
            confirmButtonText: "ปิดหน้าต่าง",
          });
        }
      });
  },
};
</script>
<style>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}
.el-select {
  position: relative !important;
  display: contents !important;
}
.el-input {
  width: 100% !important;
}
</style>
