<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">รายละเอียดการโฆษณา</h4>
        </template>
        <div>
          <div class="row">
            <div class="col-md-3">
              <fg-input
                label="วันที่"
                type="text"
                :value="result_data.datetime"
                disabled
              />
            </div>
            <div class="col-md-5">
              <fg-input
                label="แคมเปญหลัก"
                type="text"
                :value="result_data.main_campaign"
                disabled
              />
            </div>
            <div class="col-md-4">
              <fg-input
                label="แคมเปญย่อย"
                type="text"
                :value="result_data.second_campaign"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <fg-input
                label="รหัสแคมเปญ"
                type="text"
                disabled
                :value="result_data.path"
              />
            </div>
            <div class="col-md-3">
              <fg-input
                label="พนักงานรับผิดชอบ"
                type="text"
                :value="result_data.employee"
                disabled
              />
            </div>
            <div class="col-md-3">
              <fg-input
                label="แพลตฟอร์ม"
                type="text"
                :value="platform_name"
                disabled
              />
            </div>
            <div class="col-md-3">
              <fg-input
                label="ช่องทางโฆษณา"
                type="text"
                :value="channel"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input
                label="ลิ้งค์การตลาด"
                type="text"
                :value="result_data.full_url"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-9 col-sm-12 col-xl-10">
              <fg-input
                label="ลิ้งค์แคมเปญ"
                type="text"
                :value="result_data.short_url"
                disabled
              />
            </div>
            <div class="col-md-3 col-sm-12 pt-4  col-xl-2">
              <button
                class="btn btn-success w-100 text-nowrap"
                v-clipboard:copy="result_data.short_url"
                v-clipboard:success="handleCopy"
                style="margin-top:-2px"
              >
                คัดลอกลิ้งค์
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-input
                label="หมายเหตุ"
                type="text"
                :value="result_data.comment"
                disabled
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" v-if="isChart">
              <p
                class="btn btn-info"
                disabled
                style="color:#000;    font-weight: 700;    background-color: #fff;"
              >
                <i class="fa fa-link"></i> Type-Link :
                {{
                  result_data.other_link === 1
                    ? "ลิ้งค์ภายนอก"
                    : "ลิ้งค์การตลาด"
                }}
              </p>

              <div class="col-xl-12 col-md-12 mt-4">
                <stats-card
                  :title="click_url"
                  subTitle="จำนวนคลิก"
                  class="col-md-11"
                >
                  <div slot="header" class="icon-warning">
                    <i class="nc-icon nc-tap-01 text-warning"></i>
                  </div>
                  <template slot="footer">
                    <i class="fa fa-refresh"></i>Updated now :
                    {{ result_data.updatedAt }}
                  </template>
                </stats-card>
                <stats-card
                  :title="accept_url"
                  subTitle="จำนวนการตอบรับ"
                  class="col-md-11"
                >
                  <div slot="header" class="icon-success">
                    <i class="nc-icon nc-vector text-success"></i>
                  </div>
                  <template slot="footer">
                    <i class="fa fa-refresh"></i>Updated now :
                    {{ result_data.updatedAt }}
                  </template>
                </stats-card>
              </div>
            </div>
            <div class="col-md-6 text-center " v-if="isChart">
              <div v-if="!isnull">
                <chart-card
                  :chart-data="pieChart.data"
                  class="text-center"
                  chart-type="Pie"
                >
                  <template slot="header">
                    <h4 class="card-title">สรุปการดูโฆษณา</h4>
                    <p class="card-category"></p>
                  </template>
                  <template slot="footer">
                    <div class="legend">
                      <i class="fa fa-circle text-info"></i> Ios
                      {{ this.persen[0] }}
                      <i class="fa fa-circle text-danger"></i> Website
                      {{ this.persen[1] }}
                      <i class="fa fa-circle text-warning"></i> Android
                      {{ this.persen[2] }}
                    </div>
                  </template>
                </chart-card>
              </div>
              <div v-else>
                <chart-card
                  :chart-data="pieChart1.data"
                  class="text-center"
                  chart-type="Pie"
                >
                  <template slot="header">
                    <h4 class="card-title">สรุปการดูโฆษณา</h4>
                    <p class="card-category"></p>
                  </template>
                  <template slot="footer">
                    <div class="legend">
                      <i class="fa fa-circle text-info"></i> Ios
                      <i class="fa fa-circle text-danger"></i> Website
                      <i class="fa fa-circle text-warning"></i> Android
                    </div>
                  </template>
                </chart-card>
              </div>
            </div>
          </div>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard
  },
  data() {
    return {
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2"
      },

      pieChart: {
        data: {
          labels: [" ", " ", " "],
          series: []
        }
      },
      pieChart1: {
        data: {
          labels: ["ไม่มีข้อมูลสรุป"],
          series: [0]
        }
      },
      isChart: false,
      isnull: 0,
      persen: [],
      result_data: [],
      accept_url: "",
      click_url: "",
      platform_name: "",
      channel: "",
      page_width: 0
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/redirect?web=" + this.$route.query.web);
    },
    handleCopy() {
      Swal.fire({
        title: "คัดลอกลิ้งเรียบร้อย",
        text: this.result_data.short_url,
        icon: "success",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true
      });
    }
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: this.$route.query.id
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };

    await this.$axios
      .post("/detail_tracking", { token: token }, { headers: headers })
      .then(async res => {
        if (
          res.data.payload &&
          res.data.payload.Web.service_name === this.$route.query.web
        ) {
          let result_series =
            res.data.payload.device_ios +
            res.data.payload.device_web +
            res.data.payload.device_android;
          let result_labels = [];
          this.persen[0] =
            res.data.payload.device_ios > 0
              ? ((res.data.payload.device_ios * 100) / result_series).toFixed(
                  0
                ) + "%"
              : "0%";
          this.persen[1] =
            res.data.payload.device_web > 0
              ? ((res.data.payload.device_web * 100) / result_series).toFixed(
                  0
                ) + "%"
              : "0%";
          this.persen[2] =
            res.data.payload.device_android > 0
              ? (
                  (res.data.payload.device_android * 100) /
                  result_series
                ).toFixed(0) + "%"
              : "0%";

          this.pieChart.data.series[0] =
            res.data.payload.device_ios > 0
              ? (res.data.payload.device_ios * 100) / result_series
              : "";
          this.pieChart.data.series[1] =
            res.data.payload.device_web > 0
              ? (res.data.payload.device_web * 100) / result_series
              : "";
          this.pieChart.data.series[2] =
            res.data.payload.device_android > 0
              ? (res.data.payload.device_android * 100) / result_series
              : "";
          this.accept_url = res.data.payload.accept_url.toString();
          this.click_url = res.data.payload.click_url.toString();
          this.platform_name = res.data.payload.Platform.platform_name.toString();
          this.channel =
            parseInt(res.data.payload.channel) === 0 ? "Line" : "Website";
          this.result_data = res.data.payload;

          this.isnull =
            this.pieChart.data.series[0].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[1].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[2].length === 0 ? (this.isnull += 1) : -1;
          this.isnull = this.isnull === 3 ? true : false;
          this.isChart = true;
        } else {
          this.$router.push("/");
        }
      });
  }
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
</style>
