<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">เพิ่มข้อมูลสมาชิก</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-5">
                <fg-input
                  label="เบอร์โทรศัพท์"
                  type="number"
                  v-model="phone_number"
                />
              </div>
              <div class="col-md-4">
                <fg-input label="ชื่อพนักงาน" type="text" v-model="employee" />
              </div>
              <div class="col-md-3">
                <label> ตำแหน่ง</label><br />
                <el-select
                  label="แพลตฟอร์ม"
                  class="select-primary"
                  size="large"
                  placeholder="เลือกตำแหน่ง"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <l-button type="success" wide @click="handleSave()"
                  >บันทึกข้อมูล</l-button
                >&nbsp;
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import moment from "moment";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
  CheckboxGroup
} from "element-ui";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard,
    [Option.name]: Option,
    [Select.name]: Select
  },
  data() {
    return {
      phone_number: "",
      employee: "",
      rang: "",
      selects: {
        simple: "",
        countries: [
          {
            label: "EMPLOYEE",
            value: "1",
            selected: true
          },
          {
            label: "ADMIN",
            value: "2"
          }
        ]
      }
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/member");
    },
    async handleSave() {
      if (
        this.phone_number.length == 10 &&
        (this.phone_number.substring(0, 2) === "08" ||
          this.phone_number.substring(0, 2) === "09" ||
          this.phone_number.substring(0, 2) === "06")
      ) {
        if (this.selects.simple && this.employee) {
          let payload = {
            Phone_number: this.phone_number,
            Name: this.employee,
            DeptOID: this.selects.simple,
            DeptName: this.selects.simple === "2" ? "ADMIN" : "EMPLOYEE",
            RoleOID: this.selects.simple
          };
          let user = JSON.parse(localStorage.getItem("user"));
          var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
          let headers = {
            "Content-Type": "application/json",
            Authorization: "authorization " + user
          };
          await this.$axios
            .post("/add_member", { token: token }, { headers: headers })
            .then(res => {
              if (res.data.code === 0) {
                Swal.fire({
                  title: "สมัครสมาชิก สำเร็จ!",
                  text: res.data.payload.key,
                  icon: "success",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: true
                });
              } else {
                Swal.fire({
                  title: res.data.msg,
                  icon: "error",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: true
                });
              }
            })
            .catch(() => {});
        } else {
          Swal.fire({
            title: "กรอกข้อมูลให้ครบ!",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true
          });
        }
      } else {
        Swal.fire({
          title: "กรอกเบอร์โทรศัพท์ให้ถูกต้อง!",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true
        });
      }
    }
  },
  async mounted() {
    // this.website = this.$route.query.web;
    // this.datetime = moment().format("YYYY-MM-DD HH:mm:ss");
    // await this.$axios
    //   .post("/list_service", { id: this.website }, this.token)
    //   .then(async res => {
    //     if (res.data.code === 0) {
    //       this.service_id = res.data.payload[0].id;
    //       this.website = res.data.payload[0].service_name;
    //     } else {
    //       this.$router.push("/");
    //     }
    //   })
    //   .catch(err => {});
    // await this.$axios.get("/list_platform", {}, this.token).then(async res => {
    //   let selected = [];
    //   let select = [];
    //   if (res.data.code === 0) {
    //     await res.data.payload.filter(word => {
    //       if (word.id === parseInt(this.result_data.platform)) {
    //         selected.push({
    //           label: word.platform_name,
    //           value: word.id,
    //           selected: true
    //         });
    //         return word;
    //       } else {
    //         select.push({
    //           label: word.platform_name,
    //           value: word.id
    //         });
    //       }
    //     });
    //     this.selects.countries = selected.concat(select);
    //     this.showModal = true;
    //   } else {
    //     this.showModal = false;
    //     Swal.fire({
    //       text: "เกิดข้อผิดพลาด กรุณาลองใหม่",
    //       icon: "error",
    //       confirmButtonText: "ปิดหน้าต่าง"
    //     });
    //   }
    // });
  }
};
</script>
<style>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}
.el-select {
  position: relative !important;
  display: contents !important;
}
.el-input {
  width: 100% !important;
}
</style>
